@import '../../../../index.css';

.questions-mobile {
  font-family: Arial;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__block {
    width: 100%;
  }

  &__container {
    width: 100%;
    height: 100%;
    transition: 0.35s;

    &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        gap: 5px;
        width: 100%;
        height: 100%;
        transition: 0.5s;

        &__title {
            white-space: pre-line;
            width: 90%;
            font-family: Arial;
            font-weight: 800;
            line-height: 27px;
            letter-spacing: -0.5px;
            font-size: 20px;
            margin-bottom: 5px;
        }

        &__question {
            width: 80%;
            min-height: 75px;

            &__title {
              color: #5E5E5E;
              width: 100%;
              height: 35%;
              padding: 3px 0 3px 0;
            }

            &__input {
              width: 100%;
              display: flex;
              flex-direction: row;
              position: relative;
              justify-content: center;
            }

            &__error {
                color: red;
                font-size: 12px;
            }
        }

        &__button {
          font-family: Arial;
          font-size: 14px;
          font-weight: 800;
          text-transform: none;
          background-color: black;
          height: 50px;
          width: 250px;
          border-radius: 5px;
          color: white;
          margin-top: 15px;

          &:hover {
            background-color: rgb(0,0,0,0.8);
          }

          &:disabled {
            color: white;
            background-color: rgb(224,224,224,0.8);
          }
        }
    }
  }
}

@import '../../../index.css';

.breasts {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;

  &__select {
    background: #EFEFEF;

    & .MuiSelect-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
    }

    & .MuiOutlinedInput-notchedOutline {
      border-style: none
    }

    &__item {
      display: flex;
      flex-direction: row;
      gap: 5px;

      &.Mui-selected {
        background-color: rgba(0, 0, 0, 0.05) !important;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05) !important;
        }
      }

      &__text {
        font-family: Arial;
        line-height: 0;
        font-weight: 400;
      }

      &__flag-icons {
        position: absolute !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        line-height: 1em;

        &::before {
          content: " ";
        }

        &.FR {
          background-image: url(../../../assets/breasts/FR.svg);
        }
        &.GB {
          background-image: url(../../../assets/breasts/GB.svg);
        }
        &.DE {
          background-image: url(../../../assets/breasts/DE.svg);
        }
        &.IT {
          background-image: url(../../../assets/breasts/IT.svg);
        }
      }
    }
  }

  &__titles {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 70px;

    &__title {
      font-family: Arial;
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 15px;
      width: 100%;
      text-align: center;
    }
  }

  &__body {
    display: flex;

    &__title {
      font-family: Arial;
      font-weight: 700;
      width: 100%;
      text-align: start;
    }

    &__table {
      display: flex;
      align-items: center;
      justify-content: center;
      border-collapse: separate;

      &__cell {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 0;
        border-radius: 8px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.10);
        padding: 0;
        color: black;
        font-family: Arial;
        font-weight: 700;

        &:hover {
          color: white;
          background: black;
        }

        &--selected {
          color: white;
          background: black;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__continue {
      position: relative;
      font-family: Arial;
      font-weight: 800;
      text-transform: none;
      background-color: black;
      height: 50px;
      border-radius: 5px;
      color: white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;

      &:hover {
        background-color: rgb(0,0,0,0.8);
      }

      &:not(:disabled):hover &__icon {
        transition: all ease 0.2s;
        right: 30px;
      }

      &:disabled:hover &__icon {
        transition: all ease 0.2s;
        right: 40px;
      }

      &:not(:hover) &__icon {
        right: 40px;
        transition: all ease 0.2s;
      }

      &:disabled {
        color: white;
        background-color: #DDD;
      }

      &__icon {
        position: absolute;
      }
    }

    &__skip {
      font-family: Arial;
      font-weight: 400;
      padding: 0;
      text-align: left;
      background-color: none;
      color: black;
      text-transform: none;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    padding-top: 30px;
    justify-content: center;

    &__select {
      margin-bottom: 40px;
      width: 110px;
      height: 35px;

      &__item {
        padding: 0px 0px 0px 14px !important;
        min-height: 35px !important;

        &__text {
          font-size: 14px;
        }

        &__flag-icons {
          width: 20px;
          height: 14px;
          right: 28px;
          border-radius: 2px;
        }
      }
    }

    &__title {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 40px;
    }

    &__body {
      flex-direction: row;
      margin-bottom: 50px;
      gap: 70px;

      &__table {
        border-spacing: 10px 10px;

        &__cell {
          width: 50px;
          height: 50px;
          font-size: 14px;
        }
      }
    }

    &__buttons {
      width: 400px;
      gap: 10px;
      margin-top: 20px;

      &__continue {
        font-size: 14px;
        height: 55px;
      }

      &__skip {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 999px) {
    padding-top: 30px;
    justify-content: start;

    &__select {
      margin-bottom: 15px;
      width: 110px;
      height: 35px;

      &__item {
        padding: 0px 0px 0px 14px !important;
        min-height: 30px !important;

        &__text {
          font-size: 14px;
        }

        &__flag-icons {
          width: 17px;
          height: 12px;
          right: 30px;
          border-radius: 1px;
        }
      }
    }

    &__title {
      font-size: 10px;
      margin-top: 14px;
      margin-bottom: 30px;
    }

    &__body {
      flex-direction: column;
      margin-bottom: 0px;
      gap: 0px;

      &__title {
        font-size: 15px;
        margin-bottom: 4px;
      }

      &__table {
        border-spacing: 10px 8px;

        &__cell {
          width: 40px;
          height: 40px;
          font-size: 12px;
        }
      }
    }

    &__buttons {
      width: 350px;
      gap: 10px;
      margin-top: 20px;

      &__continue {
        font-size: 12px;
        height: 50px;
      }

      &__skip {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    padding-top: 30px;
    justify-content: start;

    &__select {
      margin-bottom: 15px;
      width: 100px;
      height: 30px;

      &__item {
        padding: 0px 0px 0px 14px !important;
        min-height: 30px !important;

        &__text {
          font-size: 12px;
        }

        &__flag-icons {
          width: 17px;
          height: 12px;
          right: 28px;
          border-radius: 1px;
        }
      }
    }

    &__title {
      font-size: 10px;
      margin-top: 14px;
      margin-bottom: 30px;
    }

    &__body {
      flex-direction: column;
      margin-bottom: 0px;
      gap: 0px;

      &__title {
        font-size: 14px;
        margin-bottom: 4px;
      }

      &__table {
        border-spacing: 8px 6px;

        &__cell {
          width: 35px;
          height: 35px;
          font-size: 10px;
        }
      }
    }

    &__buttons {
      width: 300px;
      gap: 10px;
      margin-top: 15px;

      &__continue {
        font-size: 10px;
        height: 50px;
      }

      &__skip {
        font-size: 10px;
      }
    }
  }
}

@import '../../../../index.css';

.intro-desktop {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;

  &__block {
    width: 80%;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 40px;
    width: 100%;
    height: 100%;

    &__title {
      white-space: pre-line;
      font-family: Arial;
      font-weight: 800;
      letter-spacing: -0.5px;
      text-align: center;
      line-height: 33px;
      font-size: 25px;
    }

    &__description {
      white-space: pre-line;
      font-family: Arial;
      font-Weight: 400;
      text-align: center;
      padding: 0;
      font-size: 16px;
      line-height: 19px;
      margin: 16px 0 15px 0;
    }

    &__buttons {
      align-self: center;
      display: flex;
      flex-direction: row;
      gap: 30px;

      &__button {
        font-family: Arial;
        font-weight: 400;
        text-transform: none;
        height: 55px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.2);
        color: black;
        border: 2px solid black;
        font-size: 18px;
        width: 180px;

        &:hover {
          border: 0;
          color: white;
          background-color: black;
        }

        &--active {
          background-color: black;
          color: white;
          border: 0;
        }
      }

      &__continue {
        font-family: Arial;
        font-size: 14px;
        font-weight: 800;
        text-transform: none;
        background-color: black;
        height: 55px;
        width: 300px;
        border-radius: 5px;
        color: white;
        margin-top: 20px;

        &:hover {
          background-color: rgb(0,0,0,0.8);
        }

        &:disabled {
          color: white;
          background-color: rgb(224,224,224,0.8);
        }
      }
    }
  }
}

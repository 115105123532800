.ruler {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 15px;
  position: relative;

  &__input {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;

    &__textfield {
      & .MuiInputBase-input {
        font-family: Arial;
        font-weight: 700;
        caret-color: black;
        padding: 0 10px;
      }

      & .MuiInputLabel-root {
        line-height: inherit;
        top: -12px;
      }

      & .MuiOutlinedInput-root {
        height: 100%;

        & fieldset {
          border-radius: 8px;
          border: 2px solid #CBCBCB;
        }

        &:hover fieldset {
          border-radius: 8px;
          border-width: 2px;
        }

        &.Mui-focused fieldset {
          border-radius: 8px;
          border: 2px black solid;
        }
      }

      & .MuiFormHelperText-root {
        font-family: Arial;
        font-size: 9px;
        font-weight: 400;
        margin: 0;
        margin-top: 3px;
      }
    }

    &__units {
      position: absolute;
      width: auto;
      height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      background-color: #f4f4f4;
      border-radius: 20px;

      &__buttons {
        color: #565656;
        border-radius: 25px;
        font-family: Arial;
        font-weight: 500;
        margin: 4px;
        text-transform: lowercase;
        width: 29px;

        &--selected {
          background-color: black;
          color: white;
        }
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    user-select: none;
    cursor: grab;
    background: linear-gradient(90deg, rgba(236, 236, 236, 0.50) 0%, rgba(177, 177, 177, 0.70) 49.87%, rgba(236, 236, 236, 0.50) 100%);
    box-shadow: 0px 0px 16.5px 0px rgba(0, 0, 0, 0.06);

    &__selector {
      position: absolute;
      top: 50%;
      width: 7px;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &__content {
      overflow-x: scroll;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        display: none;
      }

      &__box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6px;
        width: fit-content;
        height: 100%;

        &__graduation {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 1px;
          scroll-snap-align: center;

          &__cm {
            background-color: black;
            height: 20px;
            width: 1px;

            &__number {
              height: 1px;
              font-family: Arial;
              font-size: 10px;
              font-weight: 200;
              white-space: nowrap;
            }
          }

          &__mm {
            background-color: black;
            height: 10px;
            width: 1px;
          }
        }
      }
    }
  }

  &__error {
    position: absolute;
    text-align: center;
    color: red;
    font-family: Arial;
    font-style: normal;
    font-weight: 500;
  }

  @media only screen and (min-width: 1000px) {
    $ruler-width: 500px;
    $ruler-heigth: 110px;

    width: $ruler-width;
    padding-bottom: 60px;

    &__input {
      &__textfield {
        width: 130px;

        & .MuiInputBase-input {
          font-size: 46px;
        }
      }

      &__units {
        left: 140px;

        &__buttons {
          font-size: 9px;
          min-width: 40px;
          height: 20px;
        }
      }
    }

    &__container {
      &__selector {
        height: $ruler-heigth;
      }

      &__content {
        width: $ruler-width;
        height: $ruler-heigth;

        &__box {
          &__graduation {
            &:first-child {
              margin-left: $ruler-width / 2;
            }

            &:last-child {
              margin-right: $ruler-width / 2;
            }
          }
        }
      }
    }

    &__error {
      bottom: 25px;
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 999px) {
    $ruler-width: 100dvw;
    $ruler-heigth: 110px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $ruler-width;
    padding-bottom: 40px;

    &__input {
      &__textfield {
        width: 110px;

        & .MuiInputBase-input {
          font-size: 36px;
        }
      }

      &__units {
        left: 120px;

        &__buttons {
          font-size: 8px;
          min-width: 30px;
          height: 20px;
        }
      }
    }

    &__container {
      &__selector {
        height: $ruler-heigth;
      }

      &__content {
        width: $ruler-width;
        height: $ruler-heigth;

        &__box {
          &__graduation {
            &:first-child {
              margin-left: $ruler-width / 2;
            }

            &:last-child {
              margin-right: $ruler-width / 2;
            }
          }
        }
      }
    }

    &__error {
      bottom: 10px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 374px) {
    $ruler-width: 100dvw;
    $ruler-heigth: 100px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $ruler-width;
    padding-bottom: 25px;

    &__input {
      &__textfield {
        width: 110px;

        & .MuiInputBase-input {
          font-size: 36px;
        }
      }

      &__units {
        left: 120px;

        &__buttons {
          font-size: 8px;
          min-width: 30px;
          height: 20px;
        }
      }
    }

    &__container {
      &__selector {
        height: $ruler-heigth;
      }

      &__content {
        width: $ruler-width;
        height: $ruler-heigth;

        &__box {
          &__graduation {
            &:first-child {
              margin-left: $ruler-width / 2;
            }

            &:last-child {
              margin-right: $ruler-width / 2;
            }
          }
        }
      }
    }

    &__error {
      bottom: 2px;
      font-size: 12px;
    }
  }
}

@import '../../../../../index.css';

.weight_mobile {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 30px;
  gap: 25px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 0 12px;

    &__title {
      white-space: pre-line;
      font-family: Arial;
      font-weight: 800;
      line-height: 24px;
    }

    &__description {
      white-space: pre-line;
      font-family: Arial;
      font-Weight: 400;
      padding: 0;
    }
  }

  @media only screen and (max-width: 999px) {
    &__text {
      &__title {
        font-size: 20px;
      }

      &__description {
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    &__text {
      &__title {
        font-size: 18px;
      }

      &__description {
        font-size: 12px;
      }
    }
  }
}

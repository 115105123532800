@import '../../../../index.css';

.qrcode_desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;

  &__video {
    display: flex;
    flex-direction: column;
    height: 480px;
    min-width: 300px;
    position: relative;
    margin-left: 30px;

    video {
      height: 400px;
      width: 400px;
    }

    &__safety {
      text-align: center;
      font-family: Arial;
      font-size: 12px;
      margin-top: -5px;
      color: gray;
      z-index: 1;
    }

    &__help {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      text-transform: none;
      text-decoration: underline;
      text-align: center;
      font-family: Arial;
      font-weight: 400;
      color: black;
      font-size: 14px;

      &:hover {
        background-color: white;
      }

      &__icon {
        height: auto;
        width: 18px;
      }
    }

    &__copyright {
      height: fit-content;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 13px;

      p {
        color: rgba(0, 0, 0, 0.70);
        font-family: Arial;
        font-size: 10px;
        font-weight: 300;
        text-align: center;
        margin-bottom: 3px;
        cursor: default;
      }

      img {
        width: 40px;
        height: auto;
      }
    }
  }

  &__body {
    align-self: center;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 480px;
    padding-top: 40px;
    width: 70%;

    &__text {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding-right: 40px;

      &__title {
        white-space: pre-line;
        font-family: Arial;
        font-size: 26px;
        font-weight: 700;
        line-height: 33px;
      }

      &__description {
        white-space: pre-line;
        font-family: Arial;
        font-size: 18px;
        font-weight: 500;
        text-align: justify;
        line-height: 20px;
        margin: 5px 0 20px 0;
      }
    }

    &__section {
      display: flex;
      flex-direction: row;
      width: 90%;
      border-radius: 10px;
      height: 140px;
      align-items: center;
      gap: 0px;
      border: 1px black solid;
      transition: 0.15s;

      &:nth-of-type(3) {
        &:hover {
          background-color: rgb(244, 244, 244);
          border: 2px black solid;
        }

        &:active {
          opacity: 0.3
        }
      }

      &__arrow {
        margin-left: 10%;
        width: 20%;
        justify-content: flex-end;
        align-items: flex-end;
      }

      &__qrcode {
        padding: 10px 0;
        transition: 0.3s;
        min-width: 30%;
      }

      &__qrcode_animation {
        width: 120px;
        height: auto;
      }

      &__text {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        width: 70%;
        margin-left: 20px;

        &__title {
          color: black;
          font-family: Arial;
          font-size: 18px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 15px;
          margin-bottom: 15px;
        }

        &__description {
          white-space: pre-line;
          color: black;
          font-family: Arial;
          font-size: 16px;
          font-weight: 400;
          line-height: 15px;
        }
      }
    }

    &__separator {
      width: 90%;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 8px 0 8px 0;
      color: black;
      font-family: Arial;
      font-weight: 800;
      font-size: 18px;
    }

    &__skip {
      font-family: Arial;
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      text-align: left;
      background-color: none;
      color: #898989;
      text-transform: none;
      text-decoration: underline;
      position: absolute;
      bottom: 15px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

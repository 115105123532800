@import '../../../index.css';

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  &__size {
    display: flex;
    width: 100%;
    align-items: end;
    justify-content: center;
    
    &__label {
      font-family: Arial;
      font-weight: 500;
      width: 33%;

      &--focused {
        color: black;
        font-weight: 800;
      }
  
      &--notfocused {
        color: #D9D9D9;
        font-weight: 400;
      }
  
      &--centered {
        text-align: center;
      }
  
      &--started {
        text-align: center;
      }
  
      &--ended {
        text-align: center;
      }
    }
  }

  &__gradation {
    width: 100%;
    height: 10px;
    border: 1px #A4A4A4 solid;
    border-radius: 20px;
    margin: 5px;
    display: flex;

    &__box {
      min-width: 33.33%;
      height: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      z-index: 9999999;
      overflow: visible;
    }

    &__gradient {
      left: 125%;
      transition: 0.5s;
      position: absolute;
      width: 80px;
      height: 8px;
    }
  }

  &__preference {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-family: Arial;
    font-size: 15px;
    margin-top: 10px;

    &__choice {
      width: auto;
      min-width: 65%;
      display: flex;

      &__box {
        height: 100%;
        padding: 2px 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;

        &__selector {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          padding: 2px;
          margin-right: 5px;
  
          &__child {
            background-color: black;
            border: 1px solid transparent;
            border-radius: 50%;
            height: 100%;
            width: 100%;
          }
        }
      }

      &__box:hover {
        background: rgb(245, 245, 245);
      }

      &--centered {
        justify-content: center;
      }

      &--started {
        justify-content: start;
      }

      &--ended {
        justify-content: end;
      }
    }
  }

  &__advice {
    display: flex;
    width: 100%;
    height: 175px;
    align-items: center;
    justify-content: center;
    font-family: Arial;
    font-size: 15px;
    margin-top: 30px;

    &__sizing {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 20px;
      border-top: 1px grey solid;
      border-bottom: 1px grey solid;
      border-right: 1px grey solid;

      &__title {
        font-weight: 800;
        text-decoration: underline;
      }
      &__content {
        width: 95%;
        text-align: center;
      }
    }

    &__cut {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      border-bottom: 1px grey solid;

      &__title {
        font-weight: 800;
        text-align: center;
      }

      &__content {
        width: 95%;
        text-align: center;
      }

      &__type {
        font-weight: 800;
      }

      &__helper {
        font-size: 12px;
        height: 100%;
        width: 100%;
        color: grey;
        font-style: italic;
        display: flex;
        align-items: end;
        justify-content: center;
        padding: 10px 0;
        gap: 4px;

        &__text {
          position: absolute;
          background-color: rgba(239, 239, 239, 0.9);
          border: 1px lightgrey solid;
          margin-top: 7.5%;
          padding: 10px;
          width: 400px;
          height: 50px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: pre;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;

    &__save {
      font-family: Arial;
      font-weight: 800;
      text-transform: none;
      background-color: black;
      border-radius: 5px;
      color: white;
      margin-top: 10px;

      &:hover {
        background-color: rgb(0,0,0,0.8);
      }
    }

    &__text {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;

      &__restart {
        color: #898989;
        text-align: center;
        font-family: Arial;
        font-weight: 400;
        padding: 0;
        text-align: left;
        background-color: none;
        text-transform: none;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }

      &__products {
        color: #898989;
        text-align: center;
        font-family: Arial;
        font-weight: 400;
        padding: 0;
        text-align: left;
        background-color: none;
        text-transform: none;
        text-decoration: underline;
  
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    justify-content: center;

    &__size {
      min-height: 80px;
      &__label {
        font-size: 70px;
        transition: 0.5s;

        &--notfocused {
          font-size: 30px;
        }

        &--focused {
          font-size: 50px;
        }
      }
    }

    &__preference {
      &__choice {
        &__box {
          &__selector {
            border: 1px solid #b6b6b6;
            min-width: 15px;
            min-height: 15px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &__text {
      width: 400px;

      &__description {
        margin: 40px 0 40px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }

      &__hint {
        margin: 0 0 15px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }

      &__fail {
        margin: 30px 0 30px 0;
        width: 100%;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__buttons {
      width: 400px;

      &__save {
        font-size: 16px;
        height: 55px;
        width: 100%;
      }

      &__products {
        font-size: 14px;
        height: 55px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 30px;
        gap: 60px;

        &__restart {
          font-size: 14px;
          margin-top: 10px;
        }

        &__products {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 999px) {
    width: 85%;

    &__size {
      margin-bottom: 0px;
      &__label {
        line-height: 70px;
        transition: 0.5s;

        &--notfocused {
          font-size: 30px;
        }

        &--focused {
          font-size: 50px;
        }
      }
    }

    &__gradation {
      &__gradient {
        left: 100%;
        width: 70px;
      }
    }

    &__preference {
      &__choice {
        &__box {
          &__selector {
            border: 1px solid #b6b6b6;
            min-width: 15px;
            min-height: 15px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &__advice {
      height: 225px;
      margin-top: 0px;
      &__sizing {
        padding-top: 10px;
        &__content {
          font-size: 14px;
        }
      }

      &__cut {
        padding-top: 10px;
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px transparent solid;
        &__content {
          font-size: 14px;
        }
      }
    }

    &__buttons {
      width: 100%;

      &__save {
        font-size: 15px;
        height: 45px;
        width: 100%;
        margin-top: 10px;
      }

      &__products {
        font-size: 10px;
        height: 45px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 15px;
        gap: 30px;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 376px) {
    &__size {
      margin-bottom: 0px;
      &__label {
        line-height: 70px;
        transition: 0.5s;

        &--notfocused {
          font-size: 30px;
        }

        &--focused {
          font-size: 50px;
        }
      }
    }

    &__gradation {
      &__gradient {
        left: 98.5%;
        width: 70px;
      }
    }

    &__preference {
      font-size: 12px;
      &__choice {
        &__box {
          &__selector {
            border: 1px solid #b6b6b6;
            min-width: 15px;
            min-height: 15px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &__advice {
      &__sizing {
        padding-top: 5px;
        &__content {
          font-size: 13px;
        }
      }

      &__cut {
        padding-top: 5px;
          &__title {
            font-size: 14px;
          }
          &__content {
            font-size: 12px;
        }
      }
    }

    &__buttons {
      width: 100%;

      &__save {
        font-size: 13px;
        height: 45px;
        width: 100%;
        margin-top: 10px;
      }

      &__products {
        font-size: 10px;
        height: 45px;
        width: 100%;

        &:not(:first-child) {
          margin-top: 10px;
        }
      }

      &__text {
        margin-top: 10px;
        gap: 30px;
        font-size: 12px;
      }
    }
  }
}

@import '../../../../index.css';

.intro-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 30px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 0 12px;

    &__title {
      white-space: pre-line;
      font-family: Arial;
      font-weight: 800;
      line-height: 24px;
    }

    &__description {
      white-space: pre-line;
      font-family: Arial;
      font-Weight: 400;
      padding: 0;
    }
  }

  &__buttons {
    align-self: center;
    display: flex;
    flex-direction: column;

    &__button {
      font-family: Arial;
      font-weight: 400;
      text-transform: none;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      color: black;

      &:hover {
        border: 0;
        color: white;
        background-color: black;
      }

      &--active {
        background-color: black;
        color: white;
        border: 0;
      }
    }

    &__continue {
      position: absolute;
      bottom: 0;
      font-family: Arial;
      font-size: 14px;
      font-weight: 800;
      text-transform: none;
      background-color: black;
      border-radius: 5px;
      color: white;
      margin-bottom: 12px;

      &:hover {
        background-color: rgb(0,0,0,0.8);
      }

      &:disabled {
        color: white;
        background-color: rgb(224,224,224,0.8);
      }
    }
  }

  @media only screen and (max-width: 999px) {
    gap: 70px;

    &__text {
      &__title {
        font-size: 20px;
      }

      &__description {
        font-size: 14px;
      }
    }

    &__buttons {
      gap: 15px;

      &__button {
        font-size: 16px;
        width: 275px;
        height: 50px;
      }

      &__continue {
        width: 350px;
        font-size: 15px;
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    gap: 50px;

    &__text {
      &__title {
        font-size: 18px;
      }

      &__description {
        font-size: 12px;
      }
    }

    &__buttons {
      gap: 10px;

      &__button {
        font-size: 14px;
        width: 250px;
        height: 45px;
      }

      &__continue {
        width: 300px;
        font-size: 10px;
        height: 45px;
      }
    }
  }
}

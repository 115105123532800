@import '../../../../index.css';

.questions-desktop {
  font-family: Arial;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  &__block {
    width: 100%;
  }

  &__container {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    transition: transform 1.5s ease;
    transform: translateX(0%);

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 10px;
      width: 100%;
      height: 100%;
      transition: 0.5s;

      &__title {
          white-space: pre-line;
          width: 80%;
          font-family: Arial;
          font-weight: 800;
          line-height: 27px;
          letter-spacing: -0.5px;
          font-size: 25px;
          margin-bottom: 20px;
      }

      &__question {
        width: 80%;
        min-height: 75px;

        &__title {
          color: #5E5E5E;
          width: 100%;
          height: 35%;
          padding: 3px 0 3px 0;
        }

        &__input {
          width: 100%;
          display: flex;
          flex-direction: row;
          position: relative;
          justify-content: center;
        }

        &__error {
            color: red;
            font-size: 12px;
        }
      }

      &__button {
        font-family: Arial;
        font-size: 14px;
        font-weight: 800;
        text-transform: none;
        background-color: black;
        height: 55px;
        width: 300px;
        border-radius: 5px;
        color: white;
        margin-top: 20px;

        &:hover {
          background-color: rgb(0,0,0,0.8);
        }

        &:disabled {
          color: white;
          background-color: rgb(224,224,224,0.8);
        }
      }

      &__copyright {
        height: fit-content;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 13px;

        p {
          color: rgba(0, 0, 0, 0.70);
          font-family: Arial;
          font-size: 10px;
          font-weight: 300;
          text-align: center;
          margin-bottom: 3px;
          cursor: default;
        }

        img {
          width: 40px;
          height: auto;
        }
      }
    }
  }
}

@import '../../../../index.css';

.qrcode_mobile {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-top: 30px;

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    padding: 0 12px;

    &__title {
      white-space: pre-line;
      font-family: Arial;
      font-weight: 800;
      line-height: 24px;
    }

    &__description {
      white-space: pre-line;
      font-family: Arial;
      font-Weight: 400;
      padding: 0;
    }
  }

  &__safety {
    text-align: center;
    font-family: Arial;
    font-size: 12px;
    margin-top: -5px;
    color: gray;
    z-index: 1;
  }

  &__video {
    display: flex;
    justify-content: center;
  }

  &__body {
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__help {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      text-transform: none;
      text-decoration: underline;
      background-color: none;
      text-align: center;
      font-family: Arial;
      font-weight: 400;
      color: black;

      &:hover {
        background-color: white;
      }

      &__icon {
        height: auto;
      }
    }

    &__help_dialog {
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        &__title {
          text-align: center;
          margin: 10px 0 10px 0;
          font-family: Arial;
          font-weight: 700;
        }

        &__description {
          margin-bottom: 20px;
          text-align: center;
          font-family: Arial;
          font-weight: 400;
        }

        &__button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 20px;
          font-family: Arial;
          font-weight: 800;
          text-transform: none;
          background-color: black;
          border-radius: 5px;
          color: white;

          &:hover {
            background-color: rgb(0,0,0,0.8);
          }
        }
      }

      & .MuiPaper-root {
        border-radius: 15px 15px 0px 0px;
        margin: 0;
        position: fixed;
        bottom: 0%;
      }
    }

    &__start {
      font-family: Arial;
      font-weight: 800;
      text-transform: none;
      background-color: black;
      border-radius: 5px;
      color: white;

      &:hover {
        background-color: rgb(0,0,0,0.8);
      }
    }

    &__skip {
      font-family: Arial;
      font-weight: 800;
      text-transform: none;
      background-color: white;
      border-radius: 5px;
      color: black;
      border: 1px black solid;

      &:hover {
        background-color: rgb(255,255,255,0.8);
      }
    }
  }

  @media only screen and (max-width: 999px) {
    &__text {
      margin-bottom: 15px;

      &__title {
        font-size: 20px;
      }

      &__description {
        text-align: start;
        font-size: 14px;
      }
    }

    &__video {
      min-height: 210px;
      height: 210px;
      min-width: 210px;
    }

    &__body {
      justify-content: start;
      margin-top: -10px;

      &__help {
        font-size: 12px;
        margin-bottom: 10px;

        &__icon {
          width: 15px;
        }
      }

      &__help_dialog {
        & .MuiDialogContent-root {
          padding: 5px 0px 70px 0px !important;
        }

        &__content {
          padding: 0 10px;

          &__title {
            font-size: 20px;
          }

          &__description {
            font-size: 14px;
          }

          &__button {
            font-size: 12px;
            height: 50px;
            width: 350px;
          }
        }
      }

      &__start {
        font-size: 15px;
        margin-top: 15px;
        height: 50px;
        width: 350px;
      }

      &__skip {
        font-size: 15px;
        margin-top: 15px;
        height: 50px;
        width: 350px;
      }
    }
  }

  @media only screen and (max-width: 374px) {
    &__text {
      margin-bottom: 15px;

      &__title {
        font-size: 18px;
      }

      &__description {
        text-align: start;
        font-size: 12px;
      }
    }

    &__video {
      min-height: 150px;
      height: 150px;
      min-width: 150px;
    }

    &__body {
      &__help {
        font-size: 10px;
        margin-bottom: 10px;

        &__icon {
          width: 15px;
        }
      }

      &__help_dialog {
        & .MuiDialogContent-root {
          padding: 5px 0px 70px 0px !important;
        }

        &__content {
          padding: 0 5px;

          &__title {
            font-size: 18px;
          }

          &__description {
            font-size: 12px;
          }

          &__button {
            font-size: 10px;
            height: 45px;
            width: 300px;
          }
        }
      }

      &__start {
        font-size: 10px;
        margin-top: 15px;
        height: 45px;
        width: 300px;
      }

      &__skip {
        font-size: 15px;
        margin-top: 15px;
        height: 50px;
        width: 350px;
      }
    }
  }
}

.textfield {
    max-height: 75px;
    font-family: Arial;

    &__title {
        font-size: 14px;
        color: #5E5E5E;
        width: 100%;
        height: 35%;
        padding: 3px 0 3px 0;
    }

    &__input {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: center;
    }

    &__error {
        color: red;
        font-size: 12px;
    }
    @media only screen and (min-width: 1000px) {
        width: 80%;
    }

    @media only screen and (max-width: 999px) {
        width: 90%;
    }

    @media only screen and (max-width: 374px) {
        width: 90%;
    }
}
